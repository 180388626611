import { graphql } from '@/lib/gql';

import { RerenderOnSubscription } from './RerenderOnSubscription';
import { RerenderOnUserChanges } from './RerenderOnUserChanges';

const subscriptions = [
  graphql(`
    subscription VaultChanges_VaultCreated {
      onVaultCreate {
        id
      }
    }
  `),
  graphql(`
    subscription VaultChanges_ExecuteReshare {
      onExecuteReshare {
        id
      }
    }
  `),
  graphql(`
    subscription VaultChanges_AssetSettings {
      onSetVaultAssetSetting {
        id
      }
    }
  `),
  graphql(`
    subscription ActivityChanges_CreateReshare {
      onCreateReshareRequest {
        id
      }
    }
  `),
  graphql(`
    subscription ActivityChanges_ReshareVote {
      onSubmitReshareRequestVote {
        id
      }
    }
  `),
  graphql(`
    subscription ActivityChanges_TransactionUpdate {
      onTransactionUpdate {
        id
      }
    }
  `),
  graphql(`
    subscription VaultChanges_TransactionRequestUpserted {
      onTransactionRequestUpserted {
        id
      }
    }
  `),
  graphql(`
    subscription VaultChanges_TransactionUpserted {
      onTransactionUpserted {
        id
      }
    }
  `),
];

export function RerenderOnVaultChanges() {
  return (
    <>
      <RerenderOnUserChanges />

      {subscriptions.map((it, index) => (
        <RerenderOnSubscription key={index} subscription={it} />
      ))}
    </>
  );
}
