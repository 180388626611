// eslint-disable-next-line no-restricted-imports
import {
  ApolloError,
  DocumentNode,
  OperationVariables,
  SubscriptionResult,
  TypedDocumentNode,
  useSubscription,
} from '@apollo/client';
import { useRef } from 'react';

import { isSubscriptionsClientConnectionError, restartSubscriptionsClient } from '@/lib/gql-client/browser';

type Options<D, V extends OperationVariables = OperationVariables> = {
  /**
   * Called anytime a subscription receives data, both for errors and successful operations
   */
  onData: (result: SubscriptionResult<D, V>) => any;

  /**
   * Called when the subscription has an internal error, such as malformed <data></data>
   */
  onError?: (error: ApolloError) => void;

  /**
   * @returns whether the triggered subscription should be ignored and onData not be called
   */
  skip?: (result: SubscriptionResult<D, V>) => boolean;
};

/**
 *  Hook that provides the ability to run code whenever a GraphQL subscription is triggered
 */
export function useRunOnSubscription<TData = any, TVariables extends OperationVariables = OperationVariables>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  { onData = () => null, onError, skip = () => false }: Options<TData, TVariables>,
) {
  /**
   * Options are called via callbacks to avoid resubscribing if the caller does not pass memoized options
   */
  const callbackRef = useRef<typeof onData>(onData);
  callbackRef.current = onData;
  const onErrorRef = useRef<typeof onError>(onError);
  onErrorRef.current = onError;
  const skipRef = useRef<typeof skip>(skip);
  skipRef.current = skip;

  return useSubscription(subscription, {
    onData(result) {
      const { data } = result;
      if (skipRef.current?.(data)) return;
      callbackRef.current?.(data);
    },
    onComplete() {
      console.log('useRunOnSubscription completed', subscription.kind);
    },
    onError(error) {
      console.error('useRunOnSubscription error', subscription.kind, error);
      if (isSubscriptionsClientConnectionError(error)) {
        restartSubscriptionsClient();
      }
      onErrorRef.current?.(error);
    },
  });
}
