// eslint-disable-next-line no-restricted-imports
import {
  ApolloClient,
  DocumentNode,
  NoInfer,
  NormalizedCacheObject,
  OperationVariables,
  SubscriptionHookOptions,
  TypedDocumentNode,
  useSubscription,
} from '@apollo/client';
import React, { createContext, useContext, useEffect, useMemo, useRef, useTransition } from 'react';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

import { useRouter } from '@/lib/navigation';
import {
  SubscriptionsClient,
  isSubscriptionsClientConnectionError,
  restartSubscriptionsClient,
  useSubscriptionsClient,
} from '@/lib/gql-client/browser';
import { globalLogger } from '@/lib/logger';

import { useSubscriptionsRerendersWatcher } from './rerenders-watcher';

const logger = globalLogger.child({ name: 'rerender-on-subscription' });

const Context = createContext(() => {});

const DEBOUNCE_REFETCH_INTERVAL = 1000;
// Explicitly set the throttle interval to be bigger than the debounce interval
const THROTTLE_REFETCH_INTERVAL = Math.max(3000, DEBOUNCE_REFETCH_INTERVAL * 2);

export function RerenderOnSubscriptionContext({ children }: { children: React.ReactNode }) {
  const router = useRouter();

  const { canRerender } = useSubscriptionsRerendersWatcher();

  const [isPending, startTransition] = useTransition();
  const isPendingRef = useRef(isPending);
  isPendingRef.current = isPending;

  /**
   * Set the is pending ref to the current value of is pending,
   * Seting it on the render body is not reliable
   */
  useEffect(() => {
    isPendingRef.current = isPending;
  }, [isPending]);

  /**
   * Debounce the refetch function to avoid multiple refetches in a short period of time
   */
  const refetchRef = useRef(
    debounce(
      () => {
        startTransition(() => {
          return router.refresh();
        });
      },
      DEBOUNCE_REFETCH_INTERVAL,
      { leading: true },
    ),
  );

  /**
   * The value of the context is a throttled function that will refetch the page.
   * The reason for throttling on top of the debounce is to avoid starving the debounce
   * function with too many calls.
   */
  const value = useMemo(
    () =>
      throttle(
        () => {
          if (!canRerender) {
            logger.debug('rerender blocked by loading state ');
            return;
          }
          if (isPendingRef.current) return;

          refetchRef.current();
        },
        THROTTLE_REFETCH_INTERVAL,
        { trailing: true },
      ),
    [canRerender],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function RerenderOnSubscription<TData = any, TVariables extends OperationVariables = OperationVariables>(props: {
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>;
  options?: Pick<SubscriptionHookOptions<NoInfer<TData>, NoInfer<TVariables>>, 'variables'>;
}) {
  const subscriptionsClient = useSubscriptionsClient();

  return (
    <RerenderOnSubscriptionErrorBoundary subscriptionsClient={subscriptionsClient}>
      <RerenderOnSubscriptionInternal {...props} />
    </RerenderOnSubscriptionErrorBoundary>
  );
}

export function RerenderOnSubscriptionInternal<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>({
  subscription,
  options,
}: {
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>;
  options?: Pick<SubscriptionHookOptions<NoInfer<TData>, NoInfer<TVariables>>, 'variables'>;
}) {
  const onData = useContext(Context);
  useSubscription(subscription, {
    ...options,
    onData() {
      logger.debug('received subscription data');
      onData();
    },
  });

  return null;
}

/**
 * Wraps rerender components in an error boundary that will restart the subscriptions client
 * when it encounters a connection closed error error.
 */
export class RerenderOnSubscriptionErrorBoundary extends React.Component<
  { children?: React.ReactNode; subscriptionsClient: SubscriptionsClient },
  { hasError: boolean }
> {
  constructor(props: { children?: React.ReactNode; subscriptionsClient: SubscriptionsClient }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: !isSubscriptionsClientConnectionError(error) };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (isSubscriptionsClientConnectionError(error)) {
      console.error('Captured connection closed error', error, errorInfo);
      restartSubscriptionsClient();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{ children?: React.ReactNode; subscriptionsClient: ApolloClient<NormalizedCacheObject> }>,
  ) {
    if (prevProps.subscriptionsClient !== this.props.subscriptionsClient && this.state.hasError) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
