import { ApolloProvider } from '@apollo/client'; // eslint-disable-line no-restricted-imports
import React, { useSyncExternalStore } from 'react';

import { GqlClientStore, SubscriptionsClient } from './gql-client-store';

export function useSubscriptionsClient() {
  return useSyncExternalStore(GqlClientStore.subscribe, GqlClientStore.getClient, GqlClientStore.getClient);
}

export function GqlBrowserProvider({ children }: { children: React.ReactNode }) {
  const client = useSubscriptionsClient();

  return (
    <SubscriptionsConnectionCloseErrorBoundary subscriptionsClient={client}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </SubscriptionsConnectionCloseErrorBoundary>
  );
}

/**
 * Error boundary that catches connection closed errors from the subscriptions client and restarts the client.
 */
class SubscriptionsConnectionCloseErrorBoundary extends React.Component<
  { children?: React.ReactNode; subscriptionsClient: SubscriptionsClient },
  { hasError: boolean }
> {
  constructor(props: { children?: React.ReactNode; subscriptionsClient: SubscriptionsClient }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: !GqlClientStore.isSubscriptionsClientConnectionError(error) };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (GqlClientStore.isSubscriptionsClientConnectionError(error)) {
      console.error('Captured connection closed at provider error', error, errorInfo);
      GqlClientStore.restartClient();
    }
  }

  componentDidUpdate(prevProps: Readonly<{ children?: React.ReactNode; subscriptionsClient: SubscriptionsClient }>) {
    if (prevProps.subscriptionsClient !== this.props.subscriptionsClient && this.state.hasError) {
      this.setState({ hasError: false });
    }
  }

  render() {
    return this.props.children;
  }
}
