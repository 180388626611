import { graphql } from '@/lib/gql';

import { RerenderOnSubscription } from './RerenderOnSubscription';
import { RerenderOnUserChanges } from './RerenderOnUserChanges';

const subscriptions = [
  graphql(`
    subscription ComplianceChanges_FlagUpdated {
      onUpdateComplianceFlag {
        id
      }
    }
  `),
  graphql(`
    subscription ComplianceChanges_ProfileCreated {
      onCreateComplianceProfile {
        id
        organisationId
      }
    }
  `),
  graphql(`
    subscription ComplianceChanges_FlagCreated {
      onCreateComplianceFlag {
        id
        organisationId
      }
    }
  `),
  graphql(`
    subscription ComplianceChanges_IdentityBlockCreated {
      onUpdateIdentityBlockStatus {
        organisationId
      }
    }
  `),
];

export function RerenderOnComplianceChanges() {
  return (
    <>
      <RerenderOnUserChanges />

      {subscriptions.map((it, index) => (
        <RerenderOnSubscription key={index} subscription={it} />
      ))}
    </>
  );
}
