'use client';
import React from 'react';
import classNames from 'classnames';

import { buildTestId } from '@/lib/test-ids';

import styles from './Toast.module.scss';
import { Icons } from './Icons';
import { IconButton } from './IconButton';

export type ToastProps = {
  title: string;
  content: React.ReactNode;
  variant: 'warning' | 'success' | 'error' | 'info';
  'data-testid'?: string;
  onDismiss?: () => void;
};

export function Toast({ title, content, variant, 'data-testid': testId, onDismiss }: ToastProps) {
  const dismissible = Boolean(onDismiss);
  const toastClasses = classNames(styles.toast, styles[variant], {
    [styles.dismissible]: dismissible,
  });

  const IconComponent = variant === 'success' ? Icons.SuccessCircleFaded : Icons.AlertCircleFaded;

  return (
    <div className={toastClasses} data-testid={testId}>
      <div className={styles.main}>
        <div className={styles.titleWrapper}>
          <div className={styles.iconWrapper}>
            <IconComponent className={styles.icon} />
          </div>

          <span className={styles.title} data-testid={buildTestId(testId, 'title')}>
            {title}
          </span>
        </div>

        <p className={styles.content} data-testid={buildTestId(testId, 'message')}>
          {content}
        </p>
      </div>

      {dismissible ? (
        <div className={styles.dismissWrapper}>
          <IconButton as="button" iconName="Close" size="small" onClick={onDismiss} aria-label="Close" />
        </div>
      ) : null}
    </div>
  );
}
