import { useMemo, useSyncExternalStore } from 'react';

type State = { rerenderLocks: number };

let state: State = { rerenderLocks: 0 };
let subscribers = new Set<() => void>();

function emitChange() {
  for (const subscriber of subscribers) {
    subscriber();
  }
}

/**
 * This store is used to keep track of the number of rerender locks.
 * It is used to prevent rerenders triggered by subscriptions when a page or section is loading.
 * The reason for why this is needed is because continuous rerenders can cause the page to be stuck in a loading state.
 */
const store = {
  getState() {
    return state;
  },

  addRerenderLock() {
    state = { rerenderLocks: state.rerenderLocks + 1 };
    emitChange();
  },

  removeRerenderLock() {
    state = { rerenderLocks: Math.max(0, state.rerenderLocks - 1) };
    emitChange();
  },

  subscribe(callback: () => void) {
    subscribers.add(callback);
    return () => subscribers.delete(callback);
  },

  getServerSnapshot() {
    return state;
  },
};

/**
 * Export the state of the rerenders watcher store to be used in the react tree.
 */
export function useSubscriptionsRerendersWatcher() {
  const { rerenderLocks } = useSyncExternalStore(store.subscribe, store.getState, store.getServerSnapshot);
  return useMemo(
    () => ({
      canRerender: rerenderLocks === 0,
      rerenderLocks,
      addRerenderLock: store.addRerenderLock,
      removeRerenderLock: store.removeRerenderLock,
    }),
    [rerenderLocks],
  );
}
