import { graphql } from '@/lib/gql';

import { RerenderOnSubscription } from './RerenderOnSubscription';

const subscriptions = [
  graphql(`
    subscription UserChanges_RegisterDevice {
      onRegisterDevice {
        id
      }
    }
  `),
  graphql(`
    subscription UserChanges_RegisterUserDevice {
      onRegisterUserDevice {
        id
      }
    }
  `),
  graphql(`
    subscription UserChanges_RegisterVirtualSigner {
      onRegisterVirtualSigner {
        id
      }
    }
  `),
  graphql(`
    subscription UserChanges_UpdateUserDevice {
      onUpdateUserDevice {
        id
      }
    }
  `),
  graphql(`
    subscription UserChanges_UpdateVirtualSigner {
      onUpdateVirtualSigner {
        id
      }
    }
  `),
];

export function RerenderOnUserChanges() {
  return (
    <>
      {subscriptions.map((it, index) => (
        <RerenderOnSubscription key={index} subscription={it} />
      ))}
    </>
  );
}
